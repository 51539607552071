import React, { useContext } from "react"

/* Import Global Contexts */
import ProjectSortingContext from "~context/projectsSorting"
// import ProjectsContext from "~context/projects"

/* Import Local Components */
import ProjectsTableRow from "./projectsTableRow"
import ProjectsTableHeaderSortingIcon from "./projectsTableHeaderSortingIcon"

/* Import Local Style(s) */
import "./projects-table.css"

const ProjectsTable = ({ projects }) => {
  const [sortBy, setSortBy] = useContext(ProjectSortingContext).sortingState
  const [isAscending, setIsAscending] = useContext(
    ProjectSortingContext
  ).ascendingState

  const handleClick = newSortBy => {
    if (sortBy === newSortBy) {
      setIsAscending(!isAscending)
    } else {
      setSortBy(newSortBy)
      setIsAscending(false)
    }
  }

  return (
    <table className="projects-table">
      <thead>
        <tr>
          <th
            onClick={() => {
              handleClick("practice")
            }}
            style={{ opacity: sortBy === "practice" ? 1 : 0.4 }}
            className="practice"
          >
            practice
            <ProjectsTableHeaderSortingIcon
              active={sortBy === "practice"}
              isAscending={isAscending}
            />
          </th>
          <th
            onClick={() => {
              handleClick("title")
            }}
            style={{ opacity: sortBy === "title" ? 1 : 0.4 }}
            className="title"
          >
            title
            <ProjectsTableHeaderSortingIcon
              active={sortBy === "title"}
              isAscending={isAscending}
            />
          </th>
          <th
            onClick={() => {
              handleClick("artists")
            }}
            style={{ opacity: sortBy === "artists" ? 1 : 0.4 }}
            className="artists"
          >
            artists
            <ProjectsTableHeaderSortingIcon
              active={sortBy === "artists"}
              isAscending={isAscending}
            />
          </th>
          <th
            onClick={() => {
              handleClick("site")
            }}
            style={{ opacity: sortBy === "site" ? 1 : 0.4 }}
            className="site"
          >
            site
            <ProjectsTableHeaderSortingIcon
              active={sortBy === "site"}
              isAscending={isAscending}
            />
          </th>
          <th
            onClick={() => {
              handleClick("date")
            }}
            style={{ opacity: sortBy === "date" ? 1 : 0.4 }}
            className="year"
          >
            year
            <ProjectsTableHeaderSortingIcon
              active={sortBy === "date"}
              isAscending={isAscending}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {projects.map(project => (
          <ProjectsTableRow project={project} key={project._id} />
        ))}
      </tbody>
    </table>
  )
}

export default ProjectsTable
