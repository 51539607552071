import React from "react"

const ProjectsTableHeaderSortingIcon = ({ active, isAscending }) => {
  if (active) {
    if (isAscending) {
      return <span className="sortingIcon">▲</span>
    } else {
      return <span className="sortingIcon">▼</span>
    }
  } else {
    return null
  }
}

export default ProjectsTableHeaderSortingIcon
