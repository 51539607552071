import React, { useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import slug from "slug"
import BlockContent from "@sanity/block-content-to-react"

import "./filters.css"

const Filters = ({ type, selected, options, description }) => {
  const [filtersOpen, setFiltersOpen] = useState(false)

  if (
    type !== "keyword" &&
    type !== "part of" &&
    type !== "practice" &&
    type !== "format"
  ) {
    return null
  }

  return (
    <div className="filter">
      <div className="filter-selected">
        <div className="type">{type}</div>
        <button
          className="selected"
          onClick={() => {
            setFiltersOpen(!filtersOpen)
          }}
        >
          {selected}
        </button>
      </div>
      {description && (
        <div className="description">
          {<BlockContent blocks={description} />}
        </div>
      )}
      <ul className={classnames("filters", { "filters-open": filtersOpen })}>
        {options.map(option => {
          return (
            <li key={option.id}>
              <Link
                to={`/${slug(type, { lower: true })}/${slug(option.title, {
                  lower: true,
                })}`}
              >
                {option.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Filters
