import React from "react"
import slug from "slug"
import { Link } from "gatsby"

/* Import Local Styles */
import "./projects-table-row.css"

const ProjectsTableRow = ({
  project: { practiceReference, artists, title, site, date },
  project,
}) => {
  return (
    <tr className="projects-table-row">
      <td
        className={`practice ${slug(practiceReference.title)}`}
        style={{ color: project.practiceReference.color.hex }}
      >
        <Link to={`/p/${slug(title)}-${slug(site)}`}>
          <p>{practiceReference.title}</p>
        </Link>
      </td>
      <td className="title">
        <Link to={`/p/${slug(title)}-${slug(site)}`}>
          <p>{title}</p>
        </Link>
      </td>
      <td className="artists">
        <Link to={`/p/${slug(title)}-${slug(site)}`}>
          <p>{artists}</p>
        </Link>
      </td>
      <td className="site">
        <Link to={`/p/${slug(title)}-${slug(site)}`}>
          <p>{site}</p>
        </Link>
      </td>
      <td className="year">
        <Link to={`/p/${slug(title)}-${slug(site)}`}>
          <p>{date.slice(0, 4)}</p>
        </Link>
      </td>
    </tr>
  )
}

export default ProjectsTableRow
